import { DocumentNode, gql } from '@apollo/client';
import { standardEventQueryResponse } from 'services/hasura';

export interface IOrganizationService {
  create: DocumentNode;
  getAll: DocumentNode;
  getAllPayouts: (orgId: string) => DocumentNode;
  getOne: (orgId: string) => DocumentNode;
  updateOrgById: DocumentNode;
  addPayoutMethod: DocumentNode;
  deletePayout: DocumentNode;
  updatePayout: DocumentNode;
  inviteMember: DocumentNode;
  getAllMembers: (orgId: string) => DocumentNode;
  getAllOrgEvents: (orgId: string, filterBy?: string) => DocumentNode;
  getAllUsersEvents: (userId: string, filterBy?: string) => DocumentNode;
  updateMember: DocumentNode;
  deleteMember: (memberId: string) => DocumentNode;
  getMember: (memberId: string) => DocumentNode;
}

export const OrganizationService: IOrganizationService = class {
  static create = gql`
    mutation createOrganization($newOrg: organizations_insert_input!) {
      insert_organizations_one(object: $newOrg) {
        about
        created_at
        email
        id
      }
    }
  `;

  static getAll = gql`
    query MyOrganizations($userId: String) {
      organizations(
        where: {
          _and: [
            { personal: { _eq: false } }
            {
              _or: [
                { user_id: { _eq: $userId } }
                { organization_users: { user_id: { _eq: $userId } } }
              ]
            }
          ]
        }
      ) {
        about
        name
        phone
        industry
        image_url
        id
        email
        website
        social
      }
    }
  `;

  static getOne = (orgId) => gql`
    query getOneOrganization {
      organizations_by_pk(id:"${orgId}") {
        about
        name
        phone
        industry
        image_url
        id
        email
        website
        social
      }
    }
  `;

  static updateOrgById = gql`
    mutation updateOrg($orgId: uuid!, $set: organizations_set_input) {
      update_organizations_by_pk(pk_columns: { id: $orgId }, _set: $set) {
        about
        name
        phone
        industry
        image_url
        id
        email
        website
        social
      }
    }
  `;

  static addPayoutMethod = gql`
    mutation addPayouts($newPayout: organization_account_insert_input!) {
      insert_organization_account_one(object: $newPayout) {
        id
        account_name
        account_number
        account_type
        bank_name
        branch
        email
        first_name
        last_name
        organization_id
        swift_code
        phone
        organization_position
      }
    }
  `;

  static getAllPayouts = (orgId: string) => gql`
  query getAllPayouts{
    payoutMethods:organization_account(order_by: {created_at: desc}, where:{ organization_id:{ _eq:"${orgId}"}}){
        id
        account_name
        account_number
        account_type
        bank_name
        branch
        email
        first_name
        last_name
        organization_id
        swift_code
        phone
        organization_position
      }
  }`;

  static deletePayout = gql`
    mutation removePayout($payoutId: uuid!) {
      payoutMethod: delete_organization_account_by_pk(id: $payoutId) {
        account_name
        id
      }
    }
  `;

  static updatePayout = gql`
    mutation update_organization($payoutId: uuid!, $set: organization_account_set_input) {
      update_organization_account_by_pk(pk_columns: { id: $payoutId }, _set: $set) {
        id
        account_name
        account_number
        account_type
        bank_name
        branch
        email
        first_name
        last_name
        organization_id
        swift_code
        phone
        organization_position
      }
    }
  `;

  static inviteMember = gql`
    mutation inviteMembers($newMember: invites_insert_input!) {
      organizationMember: insert_invites_one(object: $newMember) {
        id
        last_name
        first_name
        email
        phone
        role
        target_id
      }
    }
  `;

  static getMember = (userID?: string) => gql`
    query getMember($memberId: uuid!) {
      invites(
        where: {
          _and: [{ id: { _eq: $memberId } }]
          user_by_email: { id: { _eq: "${userID}" } }
        }
      ) {
        id
        last_name
        first_name
        phone
        email
        status
        role
        organization {
          id
          name
          image_url
          user_id
        }
      }
    }
  `;

  static getAllMembers = (orgId: string, isOwner?: boolean) => gql`
    query OrganizationMembersInvites {
      ${
        isOwner
          ? `organizationMembers:invites(where: { target_id: { _eq: "${orgId}" } }) {
        id
        last_name
        first_name
        email
        phone
        role
        organization{
          id
          name
          user_id
        }
      }`
          : ''
      }
      members:organization_user(where: { organization_id: { _eq: "${orgId}" } }) {
        last_name
        first_name
        email
        phone
        role
        organization{
          id
          name
          user_id
        }
      }
    }
  `;

  static updateMember = gql`
    mutation updateMember($memberId: uuid!, $set: invites_set_input) {
      update_invites_by_pk(pk_columns: { id: $memberId }, _set: $set) {
        id
        last_name
        first_name
        email
        phone
        role
        status
        target_id
        organization {
          id
          name
          image_url
          user_id
        }
      }
    }
  `;

  static deleteMember = (memberId: string): DocumentNode => gql`
    mutation deleteMember {
      organizationMember:delete_invites_by_pk(id: "${memberId}") {
        first_name
        last_name
        id
      }
    }
  `;

  static getAllOrgEvents = (orgId: string): DocumentNode => gql`
  query getAllOrganizationEvents($status:String_comparison_exp, $date:timestamptz_comparison_exp){
    events(where:{ organization_id:{_eq:"${orgId}"}, status:$status, start_date:$date }){
      ${standardEventQueryResponse}
  }
}
`;

  static getAllUsersEvents = (userID: string): DocumentNode => gql`
  query getAllUsersEvents($status:String_comparison_exp, $date:timestamptz_comparison_exp){
    events(where:{ organizer: { user_id: { _eq: "${userID}" } }, status:$status, start_date:$date }){
      ${standardEventQueryResponse}
  }
}
`;
};

export default OrganizationService;
